function importAll(r) {
  let images = {};
  r.keys().map((item, index) => (images[item.replace("./", "")] = r(item)));
  return images;
}

var images = importAll(
  require.context("../assets/images", false, /\.(png|jpe?g|svg)$/)
);

// images["ZippeeLogo"] =
//   "https://zippee.delivery/wp-content/uploads/2021/05/Zippee-Logo-768x259.png";

// images["teams"] = importAll(
//   require.context("../assets/images/team", false, /\.(png|jpe?g|svg)$/)
// );

// images["investors"] = importAll(
//   require.context("../assets/images/investors", false, /\.(png|jpe?g|svg)$/)
// );

export default images;
