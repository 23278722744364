import React from 'react'
import './commonComponents.css';


const Text = ({text, appearance, color, styles}) => {
  return (
    <span className={`Text ${appearance} ${color}`} style={styles}>{text}</span>
  )
}

export default Text