import React from "react";
import Text from "../../../CommonComponents/text";
// import Button from "../../../CommonComponents/button";
import CustomButton from "../../../CommonComponents/CustomButton";
import TextField from "../../../CommonComponents/textfield";
import { Link } from "react-router-dom";

const Fulfilment = () => {
  return (
    <>
      <div className="home-fulfillment-container">
        <Text
          appearance="bold-40 fulfillment-title"
          color="black"
          text="The ‘Everything-you-Need in Fulfillment’ Platform"
        />
        <span className="light-20 fulfillment-text">Welcome to the world of <span style={{color: '#F4C244', fontWeight: '600'}}>full-stack Intelligent Fulfillment</span> that keeps your customers coming back.
          </span>
        <Link to="/contact-us">
          <CustomButton variant="primary">
            <TextField color="black" appearance="bold-20">
              GET IN TOUCH
            </TextField>
          </CustomButton>
          {/* <Button text="GET IN TOUCH" variant="primary" /> */}
        </Link>
      </div>
    </>
  );
};

export default Fulfilment;
