import React from "react";
import Text from "../../CommonComponents/text";
import "./cards.css";

const Card = ({
  title,
  description,
  effects,
  img_src,
  text_color,
  title_appearance,
  description_appearance,
  img_class,
}) => {
  return (
    <div>
      <div className="card">
        <img src={img_src} className={`${effects} ${img_class}`} alt="Avatar" />
        <div className={`card-container`}>
          <Text text={title} color="black" appearance={title_appearance} />
          <Text
            text={description}
            color="black"
            appearance={description_appearance}
          />
        </div>
      </div>
    </div>
  );
};

export default Card;
