import React from "react";
import Text from "../../../CommonComponents/text";

const Stats = () => {
  return (
    <>
      <div className="home-stats-container">
        <div className="home-stats-text-container">
          <Text appearance="bold-60" color="black" text="25%" />
          <Text
            appearance="bold-16"
            color="black"
            text="Fulfillment Cost Savings"
          />
        </div>
        <div className="home-stats-text-container">
          <Text appearance="bold-60" color="black" text=">98.5%" />
          <Text appearance="bold-16" color="black" text="SDD Accuracy" />
        </div>
        <div className="home-stats-text-container">
          <Text appearance="bold-60" color="black" text="100%" />
          <Text appearance="bold-16" color="black" text="Customer Delight" />
        </div>
        <div className="home-stats-text-container">
          <Text appearance="bold-60" color="black" text="90%" />
          <Text
            appearance="bold-16"
            color="black"
            text="Upto 90% Reduction in RTOs"
          />
        </div>
      </div>
    </>
  );
};

export default Stats;
