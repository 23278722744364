import React from "react";
import LottieCard from "../../../Components/Cards/LottieCard";
import Text from "../../../CommonComponents/text";
import warehouseAnimation from "../../../assets/lottie_animations/warehouse.json";
import customerAnimation from "../../../assets/lottie_animations/customer.json";
import connectAnimation from "../../../assets/lottie_animations/connect.json";
import sameDayDeliveryAnimation from "../../../assets/lottie_animations/same_day_delivery.json";
import onboardingAnimation from "../../../assets/lottie_animations/onboarding.json";
import packAnimation from "../../../assets/lottie_animations/packing.json";

const HowItWorks = () => {
  return (
    <div className="home-works-container" id="home-works-container-id">
      <Text color="black" appearance="bold-40" text="How It Works" />

      <div className="home-works-cards">
        <LottieCard
          title="1. Onboard"
          description="Hassle-free Onboarding directly with us."
          text_color="black"
          animation_src={onboardingAnimation}
          title_appearance="bold-20"
          width="200px"
          height="auto"
          description_appearance="text-align-center"
        />
        <LottieCard
          title="2. Connect"
          description="Seamless API integration for your online store."
          text_color="black"
          animation_src={connectAnimation}
          title_appearance="bold-20"
          width="200px"
          height="auto"
          description_appearance="text-align-center"
        />
        <LottieCard
          title="3. Store"
          description="Efficient inventory management at our dark store locations."
          text_color="black"
          animation_src={warehouseAnimation}
          title_appearance="bold-20"
          width="300px"
          height="auto"
          description_appearance="text-align-center"
        />
        <LottieCard
          title="4. Pick and Pack"
          description="Efficient pick & pack service for your orders, following your SLAs and SOPS."
          text_color="black"
          animation_src={packAnimation}
          title_appearance="bold-20"
          width="300px"
          height="auto"
          description_appearance="text-align-center"
        />
        <LottieCard
          title="5. Same-Day Delivery"
          description="Same-Day Delivery of your orders using our Zippee fleet"
          text_color="black"
          animation_src={sameDayDeliveryAnimation}
          title_appearance="bold-20"
          width="200px"
          height="auto"
          description_appearance="text-align-center"
        />

        <LottieCard
          title="6. Delighted Customer"
          description="Delight your customers with faster order delivery- than ever before."
          text_color="black"
          animation_src={customerAnimation}
          title_appearance="bold-20"
          width="400px"
          height="auto"
          description_appearance="text-align-center"
        />
      </div>
    </div>
  );
};

export default HowItWorks;
