import { React, useState } from "react";
import "./ContactUs.css";
// import FormComponent from "./Components/FormComponents";
// Custom Components
import Text from "../../CommonComponents/text";
import Button from "../../CommonComponents/button";

import WhatsappButton from "../../Components/WhatsappButton";
import Modal from "@mui/material/Modal";

// Material Ui Component
import Paper from "@mui/material/Paper";
import axios from "axios";
import {
  Radio,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";

//Formik Import
import { Field, Formik } from "formik";

//yup import
import * as Yup from "yup";

import { BsFillCheckCircleFill } from "react-icons/bs";
import { ModalClose, ModalDialog } from "@mui/joy";

const ContactUs = () => {
  const [sent, setSent] = useState(null);
  const [loader, setLoader] = useState(false);

  const [showModal, setShowModal] = useState(false); // State to manage modal visibility

  //Creating Yup Schema
  const validateSchema = Yup.object().shape({
    brand: Yup.string().required("Brand Name is Required"),
    email: Yup.string().email("Invalid Email").required("Email is Required"),
    phone: Yup.string().matches(
      /^[0-9]{10}$/,
      "Mobile Number Should be of 10 Digits"
    ),
    name: Yup.string().required("Your Full Name is Required"),
    job: Yup.string().required("Job is Required"),
    message: Yup.string().required("Message is Required"),
    orders: Yup.string().required("Please Select an Option"),
    platform: Yup.string().required("Please Select an Option"),
  });

  const companiesImages = [
    "The_Face_Shop.png",
    "GadreMarine.png",
    "Epigamia.png",
    "PowerGummies.png",
    "Perfora.png",
    "Baskin-Robbins.png",
    "Clinikally.png",
    "Anveshan.png",
    "Lenskart.jpeg",
    "Minus30.png",
  ];

  const image_base_url =
    "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/brands/";

  const listIconStyle = {
    color: "#F4C244",
    minWidth: "1em",
  };

  const closeModal = () => {
    // Close the modal and reset the message state
    setShowModal(false);
    setSent(null);
  };

  return (
    <div>
      <div className="contact-us-page">
        <div className="contact-us-page-container">
          <div className="contact-us-title">
            <h1>Request Access</h1>
            <br />
            <Text
              appearance="light-16 text-align-center"
              text="E-commerce is becoming increasingly competitive with shifting brand loyalties & rising CAC. Let us help you stay ahead of the curve through faster delivery across India. Fill up the form & we’ll get back shortly!"
              color="black"
            />
          </div>
          <div className="contact-us-form-container">
            <Paper elevation={6}>
              <Formik
                initialValues={{
                  brand: "",
                  email: "",
                  phone: "",
                  name: "",
                  job: "",
                  orders: "",
                  platform: "",
                  message: "",
                }}
                //Validation
                validationSchema={validateSchema}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  setSubmitting(true);
                  try {
                    const body = {
                      brand_name: values.brand,
                      email: values.email,
                      mobile_no: values.phone,
                      contact_person_name: values.name,
                      contact_person_job_title: values.job,
                      orders_per_month: values.orders,
                      eCommerce_platform: values.platform,
                      message: values.message,
                    };
                    setLoader(true);
                    const response = await axios.post(
                      process.env.REACT_APP_BASE_URL + "/api/1/contact-us/",
                      body,
                      {
                        headers: {
                          "x-api-key": process.env.REACT_APP_API_KEY,
                          "Content-Type": "application/json",
                        },
                      }
                    );

                    setSent(
                      response.data.status === "error" ? (
                        "Something went wrong..."
                      ) : (
                        <span className="success-message">
                          "Thank you for reaching out. We will get back to you
                          shortly!"
                        </span>
                      )
                    );
                    resetForm();
                    setShowModal(true);
                    setLoader(false);
                  } catch (error) {
                    console.error("Error submitting form:", error);
                    setSent("Something went wrong...");
                  } finally {
                    setSubmitting(false);
                  }
                }}
              >
                {(formik) => (
                  <form id="contact-us-form-id" onSubmit={formik.handleSubmit}>
                    {console.log(formik.errors)}
                    <label className="contact-us-label-input-text">
                      Your Brand*
                      <TextField
                        className="contact-us-input"
                        variant="outlined"
                        size="small"
                        name="brand"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.brand}
                      />
                      {/* Showing Errors*/}
                      {formik.touched.brand && formik.errors.brand && (
                        <span className="error-message">
                          {formik.errors.brand}
                        </span>
                      )}
                    </label>
                    <label className="contact-us-label-input-text">
                      Email*
                      <TextField
                        className="contact-us-input"
                        variant="outlined"
                        size="small"
                        name="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                      {/* Showing Errors*/}
                      {formik.touched.email && formik.errors.email && (
                        <span className="error-message">
                          {formik.errors.email}
                        </span>
                      )}
                    </label>
                    <label className="contact-us-label-input-text">
                      Phone No*
                      <TextField
                        className="contact-us-input"
                        variant="outlined"
                        size="small"
                        name="phone"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phone}
                      />
                      {/* Showing Errors*/}
                      {formik.touched.phone && formik.errors.phone && (
                        <span className="error-message">
                          {formik.errors.phone}
                        </span>
                      )}
                    </label>
                    <label className="contact-us-label-input-text">
                      Your Name*
                      <TextField
                        className="contact-us-input"
                        variant="outlined"
                        size="small"
                        name="name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                      />
                      {/* Showing Errors*/}
                      {formik.touched.name && formik.errors.name && (
                        <span className="error-message">
                          {formik.errors.name}
                        </span>
                      )}
                    </label>
                    <label className="contact-us-label-input-text">
                      Job Title*
                      <TextField
                        className="contact-us-input"
                        variant="outlined"
                        size="small"
                        name="job"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.job}
                      />
                      {/* Showing Errors*/}
                      {formik.touched.job && formik.errors.job && (
                        <span className="error-message">
                          {formik.errors.job}
                        </span>
                      )}
                    </label>
                    <FormLabel>
                      How many orders per month?*
                      <Field
                        as={RadioGroup}
                        name="orders"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <FormControlLabel
                          value="0-500"
                          control={<Radio />}
                          label="0-500"
                        />
                        <FormControlLabel
                          value="501-1000"
                          control={<Radio />}
                          label="501-1000"
                        />
                        <FormControlLabel
                          value="1001-5000"
                          control={<Radio />}
                          label="1001-5000"
                        />
                        <FormControlLabel
                          value="5001+"
                          control={<Radio />}
                          label="5001+"
                        />
                      </Field>
                      {formik.touched.orders && formik.errors.orders && (
                        <span className="error-message">
                          {formik.errors.orders}
                        </span>
                      )}
                    </FormLabel>
                    <FormLabel>
                      E-commerce Platform*
                      <Field
                        as={RadioGroup}
                        name="platform"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <FormControlLabel
                          value="Shopify"
                          control={<Radio />}
                          label="Shopify"
                        />
                        <FormControlLabel
                          value="Shopify Plus"
                          control={<Radio />}
                          label="Shopify Plus"
                        />
                        <FormControlLabel
                          value="WooCommerce"
                          control={<Radio />}
                          label="WooCommerce"
                        />
                        <FormControlLabel
                          value="Big Commerce"
                          control={<Radio />}
                          label="Big Commerce"
                        />
                        <FormControlLabel
                          value="Magento"
                          control={<Radio />}
                          label="Magento"
                        />
                        <FormControlLabel
                          value="Custom Built"
                          control={<Radio />}
                          label="Custom Built"
                        />
                        <FormControlLabel
                          value="Other"
                          control={<Radio />}
                          label="Other"
                        />
                      </Field>
                      {formik.touched.platform && formik.errors.platform && (
                        <span className="error-message">
                          {formik.errors.platform}
                        </span>
                      )}
                    </FormLabel>
                    <label>Your message* </label>
                    <Field
                      as="textarea"
                      rows="5"
                      cols="5"
                      style={{
                        resize: "none",
                        padding: "10px 10px",
                        outlineStyle: "solid",
                        outlineColor: "#FFFFFF",
                      }}
                      name="message"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    ></Field>{" "}
                    {formik.touched.message && formik.errors.message && (
                      <span className="error-message">
                        {formik.errors.message}
                      </span>
                    )}
                    <Button
                      type="submit"
                      text="REQUEST ACCESS"
                      variant="primary"
                      loader={loader}
                    />
                  </form>
                )}
              </Formik>
            </Paper>

            <Modal open={showModal}>
              <ModalDialog color="neutral" variant="soft">
                <ModalClose onClick={closeModal} />
                <Typography>
                  {sent === "Something went wrong..." ? "Error" : "Success"}
                  <p>{sent}</p>
                </Typography>
              </ModalDialog>
            </Modal>

            <div className="contact-us-why-zippee">
              <div>
                <Text
                  appearance="bold-40"
                  text="Trusted by the Best"
                  color="black"
                />
                <div className="contact-us-brand-icons">
                  {companiesImages.map((company, index) => (
                    <img key={index} src={image_base_url + company} alt="" />
                  ))}
                </div>
              </div>
              <div>
                <Text appearance="bold-30" text="Why Zippee ?" color="black" />
                <ul>
                  <li>
                    <BsFillCheckCircleFill style={listIconStyle} />
                    Increase customer delight through an unforgettable Same-Day
                    Delivery experience
                  </li>
                  <li>
                    <BsFillCheckCircleFill style={listIconStyle} />
                    Network of dark stores and unified last-mile delivery across
                    India
                  </li>
                  <li>
                    <BsFillCheckCircleFill style={listIconStyle}>
                      your_icon_name
                    </BsFillCheckCircleFill>
                    Boost repeat purchases & loyalty metrics while reducing RTOs
                    by up to 90%
                  </li>
                  <li>
                    <BsFillCheckCircleFill style={listIconStyle}>
                      your_icon_name
                    </BsFillCheckCircleFill>
                    Best-in-class tech stack & seamless integrations
                  </li>
                </ul>
              </div>
              <div>
                <Text text="Next Steps:" color="black" appearance="bold-20" />
                <ul>
                  <li>
                    <BsFillCheckCircleFill style={listIconStyle} />A Zippee
                    expert will reach out on email shortly
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WhatsappButton />
    </div>
  );
};

export default ContactUs;
