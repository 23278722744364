import React from "react";
import Text from "../../../CommonComponents/text";
import { TypeAnimation } from "react-type-animation";

const Companies = () => {
  const companies_images_src = [
    "Clinikally.png",
    "supertails.jpg",
    // "Rage-Coffee.png",
    "Epigamia.png",
    "Anveshan.png",
    "PowerGummies.png",
    "Lenskart.jpeg",
    "GadreMarine.png",
    "TheMoms.png",
    "The_Face_Shop.png",
    "Sepoy_co.png",
    "Dr-Vaidyas.png",
    "Baskin-Robbins.png",
    // "Beardo.jpeg",
    // "Renee.jpeg",
    // "Noto.png",
    // "Namhya.png",
    "Neemans.svg",
    // "Almo.png",
    "MinusThirty.png",
    "MasterChow.png",
    "2brothers.png",
    "Borecha.png",
    "Ultrahuman.png",
    "Haldiram.jpg",
    "Perfora.png",
    "peesafe.jpeg",
    "bartisans.png",
    "NatXtra.png",
    // "yellow.png",
    // "Mondelez.webp",
    "cosiq.png",
    "frido.jpg",
  ];
  var img_link =
    "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/brands/";
  return (
    <>
      <div className="home-brand-container">
        <div>
          <Text
            color="black"
            appearance="bold-30"
            text="Founders of India's biggest brands trust Zippee to improve "
          />
          <br />
          <TypeAnimation
            sequence={[
              "Website Conversions ",
              2000,
              "RTOs ",
              2000,
              "Fulfillment Times ",
              2000,
              "Customer Loyalty ",
              2000,
            ]}
            wrapper="span"
            cursor={true}
            className="bold-30 secondary-color"
            deletionSpeed={{ type: "keyStrokeDelayInMs", value: 20 }}
            speed={{ type: "keyStrokeDelayInMs", value: 20 }}
            repeat={Infinity}
            style={{
              display: "inline-block",
            }}
          />
        </div>

        <div className="home-brand-icons">
          {companies_images_src.map((img_src, index) => (
            <img key={index} src={img_link + img_src} alt="" />
          ))}
        </div>
      </div>
    </>
  );
};

export default Companies;
