import React from "react";
import "./Carousel.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

export default function CustomCarousel() {
  const img = {
    width: '0px', /* Set the desired width */
    height: '3000px' /* Maintain the aspect ratio */
}
  return (
    <div className="custom-react-carousel blog-carousel">
      <Carousel
        className="crausel-change-css"
        showStatus={false}
        showThumbs={false}
        autoPlay={true}
        infiniteLoop={true}
      >
        <div className="carousel_blogs">
          <img className="carousel_blog" src="https://zippee-delivery-assets.s3.amazonaws.com/blog/slider-blogs/Frame 133659 .jpg" alt="" />
          <p className="legend">Legend 1</p>
        </div>
      </Carousel>
    </div>
  );
}
