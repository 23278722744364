// import React from "react";
// import Text from "../../CommonComponents/text";
// import "./cards.css";

// const ProfileCard = ({ name, job_title, img_src }) => {
//   return (
//     <div className="profile-card">
//       <div className="circular-portrait">
//         <img src={img_src} alt="" />
//       </div>
//       <div className={`profile-card-container`}>
//         <Text text={name} appearance="bold-16 text-align-center" />
//         <Text text={job_title} />
//       </div>
//     </div>
//   );
// };

// export default ProfileCard;

import React from "react";
import Text from "../../CommonComponents/text";
import "./cards.css";

const ProfileCard = ({ name, job_title, img_src }) => {
  return (
    <div className="profile-card">
      <div className="circular-portrait">
        <img src={img_src} alt={name} />
      </div>
      <div className="profile-card-container">
        <Text text={name} appearance="bold-16 text-align-center black-text" />
        <Text text={job_title} appearance="text-align-center black-text" />
      </div>
    </div>
  );
};

export default ProfileCard;
