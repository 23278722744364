import { React, useState } from "react";
import "./footer.css";
import Text from "../../CommonComponents/text";
import images from "../../helpers/ImportImages";
import { Link, useLocation } from "react-router-dom";
import { FaXTwitter } from "react-icons/fa6";
import { IconContext } from "react-icons";
import { FaAngellist } from "react-icons/fa6";
import { BsPhoneFill } from "react-icons/bs";
import { SlLocationPin } from "react-icons/sl";
import { MdEmail } from "react-icons/md";
import { FaLinkedin } from "react-icons/fa";

const Footer = () => {
  return (
    <div>
      <div className="footer">
        <div className="footer-container">
          <div className="footer-col">
            <Link to="/">
              <img src={images["Zippee-Logo.png"]} alt="" />
            </Link>

            <IconContext.Provider value={{ color: "white", size: "23px" }}>
              <div
                className="footer-icons"
                style={{ marginTop: "10px", marginLeft: "10px" }}
              >
                <Link
                  to="https://www.linkedin.com/company/zippee/"
                  target="_blank"
                >
                  <FaLinkedin />
                </Link>
                <Link to="https://twitter.com/zippeeindia" target="_blank">
                  <FaXTwitter />
                </Link>
                <Link to="https://wellfound.com/company/zippee" target="_blank">
                  <FaAngellist />
                </Link>
              </div>
            </IconContext.Provider>
            <div className="footer-col-3 for_text">
              <Text text="Zippee improves customer loyalty & RTOs for India's top Ecommerce brands by enabling Quick Commerce Delivery- via their own websites." />
              <div style={{ marginTop: "30px" }}>
                <img src={images["StartupIndialogo.png"]} alt="" />
              </div>
            </div>
          </div>
          <div className="footer-col footer-col-2 left_align_items">
            <div className="chile-footer-col-1">
              <div className="footer-header">
                <Text appearance="footer-header-links" text="Company" />
                <Link to="/">
                  <Text appearance="footer-links" text="Home" />
                </Link>
                <Link to="/about">
                  <Text appearance="footer-links" text="About" />
                </Link>
                <Link
                  to="https://zfwindia.notion.site/ZFW-Careers-06c01cf4e6cb48adac0bf98360346805"
                  target="_blank"
                >
                  <Text appearance="footer-links" text="Careers" />
                </Link>
              </div>
              <div className="footer-header">
                <Text appearance="footer-header-links" text="Features" />
                <Link to="/track-order">
                  <Text appearance="footer-links" text="Track Order" />
                </Link>
                <Link to="https://blaze.zippee.delivery/sign-in">
                  <Text appearance="footer-links" text="Blaze" />
                </Link>
              </div>
              <div className="footer-header">
                <Text appearance="footer-header-links" text="Resources" />
                <Link to="/news">
                  <Text appearance="footer-links" text="News" />
                </Link>
                <Link to="/blogs">
                  <Text appearance="footer-links" text="Blogs" />
                </Link>
              </div>
            </div>
          </div>
          <div className="footer-col footer-col-3 right_align_items">
            <div className="footer-reach-us">
              <span className="footer-header-links">Reach us</span>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Text
                appearance="footer-links"
                text={
                  <>
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <BsPhoneFill style={{ marginRight: "8px" }} />
                      +91 95992 20400
                    </span>
                  </>
                }
                styles={{ fontSize: "18px", marginTop: "25px" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "15px",
              }}
            >
              <Text
                appearance="footer-links"
                text={
                  <>
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <MdEmail style={{ marginRight: "8px" }} />
                      <a
                        className="mail-to"
                        href="mailto:hello@zippee.delivery"
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          transition: "color 0.3s",
                        }}
                        onMouseEnter={(e) => {
                          e.target.style.color = "#F4C20D";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.color = "inherit";
                        }}
                      >
                        hello@zippee.delivery
                      </a>
                    </span>
                  </>
                }
                styles={{ fontSize: "18px" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "15px",
              }}
            >
              <Text
                appearance="footer-links"
                text={
                  <>
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <SlLocationPin style={{ marginRight: "8px" }} />
                      345, Udyog Vihar II, Sector 20,
                    </span>
                  </>
                }
                styles={{ fontSize: "18px" }}
              />
              <Text
                appearance="footer-links"
                text="Gurugram, Haryana 122008"
                styles={{ marginLeft: "25px", fontSize: "18px" }}
              />
            </div>
          </div>
        </div>
        <div
          className="footer_bottom"
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            padding: "0 0 0 32px ",
          }}
        >
          <div className="footer-col footer-col-2" style={{ padding: "0px" }}>
            <Text
              text="© 2024 Zippee. All Rights Reserved."
              appearance="light-16 footer-links footer-copy-right"
              styles={{ fontSize: "15px" }}
            />
          </div>
          <div
            className="footer-col"
            style={{
              padding: "0px",
              gap: "6px",
              flexDirection: "row",
              // justifyContent: "center",
              // marginRight:"520px"
            }}
          >
            <Link to="/terms-condition">
              <Text
                appearance="footer-links"
                text="Terms & Conditions | "
                styles={{ fontSize: "15px" }}
              />
            </Link>
            <Link to="/privacy-policy">
              <Text
                appearance="footer-links"
                text="Privacy Policy | "
                styles={{ fontSize: "15px" }}
              />
            </Link>
            <Link to="/refund-policy">
              <Text
                appearance="footer-links"
                text="Refund Policy"
                styles={{ fontSize: "15px" }}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
