import React from "react";
import "./commonComponents.css";
import { CircularProgress } from "@mui/material";

const Button = ({ type, text, variant, onClick, loader }) => {
  return (
    <button type={type} className={`Button ${variant}`} onClick={onClick}>
      {loader ? <CircularProgress /> : text}
    </button>
  );
};

export default Button;
