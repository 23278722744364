const investors = [
  {
    name: "Kunal Shah",
    priority: "1",
    image_url:
      "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/investors/1_KunalShah(Cred).png",
    company: " Cred ",
  },
  {
    name: "Ashneer Grover",
    priority: "2",
    image_url:
      "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/investors/2_AshneerGrover(Bharatpe).jpeg",
    company: "Bharatpe",
  },
  {
    name: "Peyush Bansal",
    priority: "3",
    image_url:
      "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/investors/3_PeyushBansal(Lenskart).jpeg",
    company: "Lenskart",
  },
  {
    name: "Arjun Vaidya",
    priority: "4",
    image_url:
      "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/investors/4_ArjunVaidya(Dr.Vaidya).jpeg",
    company: "Dr. Vaidya",
  },
  {
    name: "Mohit Sadaani",
    priority: "5",
    image_url:
      "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/investors/5_MohitSadaani(TheMom\u2019sCo).jpeg",
    company: "The Mom\u2019s Co",
  },
  {
    name: "Prashant Pitti",
    priority: "6",
    image_url:
      "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/investors/6_PrashantPitti(EaseMyTrip).jpeg",
    company: "EaseMyTrip",
  },
  {
    name: "Shailesh Karwa",
    priority: "7",
    image_url:
      "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/investors/7_ShaileshKarwa(Workstore).jpeg",
    company: "Workstore",
  },
  {
    name: "Ashutosh & Priyank",
    priority: "8",
    image_url:
      "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/investors/8_Ashutosh&Priyank(Beardo).jpg",
    company: "Beardo",
  },
  {
    name: "Raj Shamani",
    priority: "9",
    image_url:
      "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/investors/9_RajShamani(HouseofX).jpeg",
    company: "HouseofX",
  },
  {
    name: "Jasminder Gulati",
    priority: "10",
    image_url:
      "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/investors/10_JasminderGulati(NowFloats).jpg",
    company: "NowFloats ",
  },
  {
    name: "Bharat Sethi",
    priority: "11",
    image_url:
      "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/investors/11_BharatSethi(RageCoffee).jpg",
    company: "Rage Coffee",
  },
  {
    name: "Amar Chhabra",
    priority: "12",
    image_url:
      "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/investors/12_AmarChhabra(Neeman\u2019s).png",
    company: "Neeman\u2019s",
  },
  {
    name: "Rohan & Rahul",
    priority: "13",
    image_url:
      "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/investors/13_Rohan&Rahul(Epigamia).png",
    company: "Epigamia",
  },
  {
    name: "Archana Priyadarshini",
    priority: "14",
    image_url:
      "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/investors/14_ArchanaPriyadarshini(fwdSlashCapital).jpeg",
    company: "fwdSlash Capital",
  },
  {
    name: "Aakash Anand",
    priority: "15",
    image_url:
      "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/investors/15_AakashAnand(BellaVitaOrganics).jpeg",
    company: "Bella Vita Organics",
  },
  {
    name: "Amanpreet Bajaj",
    priority: "16",
    image_url:
      "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/investors/16_AmanpreetBajaj(Letsbuyext:Flipkart).jpg",
    company: "Letsbuy  ext: Flipkart",
  },
  {
    name: "Varun Vohra",
    priority: "17",
    image_url:
      "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/investors/17_VarunVohra(AknaMed).jpg",
    company: "AknaMed",
  },
  {
    name: "Bhavik Vasa",
    priority: "18",
    image_url:
      "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/investors/18_BhavikVasa(GetVantage).jpeg",
    company: "GetVantage",
  },
  {
    name: "Sharan Hegde",
    priority: "19",
    image_url:
      "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/investors/19_SharanHegde(FinancewithSharan).jpeg",
    company: "Finance with Sharan",
  },
];

export default investors;
