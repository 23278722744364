import React from "react";
import Text from "../../CommonComponents/text";
import "./privacyPolicy.css";
import TextField from "../../CommonComponents/textfield";

const privacyPolicy = () => {
  return (
    <>
      <div className="privacy-policy-page">
        
        <Text text="Privacy Policy" color="black" appearance="bold-50" />
        <div className="privacy-policy-container">
          <Text
            text="Last updated: 29 March 2023"
            color="black"
            appearance="light-16"
          />
          <Text
            text="This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You."
            color="black"
            appearance="light-16"
          />
          <Text
            text="We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy."
            color="black"
            appearance="light-16"
          />
          <Text
            text="Interpretation and Definitions"
            color="black"
            appearance="bold-40"
          />
          <Text text="Interpretation" color="black" appearance="bold-30" />
          <Text
            text="The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural."
            color="black"
            appearance="light-16"
          />

          <Text text="Definitions" color="black" appearance="bold-30" />
          <Text
            text="For the purposes of this Privacy Policy:"
            color="black"
            appearance="light-16"
          />

          <ul>
            <li>
              <Text text="Account " color="black" appearance="bold-16" />
              <Text
                text="means a unique account created for You to access our Service or parts of our Service."
                color="black"
                appearance="light-16"
              />
            </li>
            <li>
              <Text text="Company" color="black" appearance="bold-16" />
              <Text
                text=" (referred to as either ‘the Company’, ‘We’, ‘Us’ or ‘Our’ in this Agreement) refers to ZFW Hospitality Private Limited, D3/3251 Vasant Kunj."
                color="black"
                appearance="light-16"
              />
            </li>
            <li>
              <Text text="Cookies" color="black" appearance="bold-16" />
              <Text
                text=" are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses."
                color="black"
                appearance="light-16"
              />
            </li>
            <li>
              <Text text="Country" color="black" appearance="bold-16" />
              <Text
                text=" refers to: Delhi, India"
                color="black"
                appearance="light-16"
              />
            </li>
            <li>
              <Text text="Device" color="black" appearance="bold-16" />
              <Text
                text=" means any device that can access the Service such as a computer, a cellphone or a digital tablet."
                color="black"
                appearance="light-16"
              />
            </li>
            <li>
              <Text text="Personal Data" color="black" appearance="bold-16" />
              <Text
                text=" is any information that relates to an identified or identifiable individual."
                color="black"
                appearance="light-16"
              />
            </li>

            <li>
              <Text text="Service" color="black" appearance="bold-16" />
              <Text
                text=" refers to the Website."
                color="black"
                appearance="light-16"
              />
            </li>
            <li>
              <Text
                text="Service Provider"
                color="black"
                appearance="bold-16"
              />
              <Text
                text=" means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used."
                color="black"
                appearance="light-16"
              />
            </li>
            <li>
              <Text
                text="Third-party Social Media Service"
                color="black"
                appearance="bold-16"
              />
              <Text
                text=" refers to any website or any social network website through which a User can log in or create an account to use the Service."
                color="black"
                appearance="light-16"
              />
            </li>
            <li>
              <Text text="Usage Data" color="black" appearance="bold-16" />
              <Text
                text=" refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit)."
                color="black"
                appearance="light-16"
              />
            </li>
            <li>
              <Text text="Website" color="black" appearance="bold-16" />
              <Text
                text=" refers to ZFW Hospitality Private Limited, accessible from"
                color="black"
                appearance="light-16"
              />
            </li>
            <li>
              <Text text="You" color="black" appearance="bold-16" />
              <Text
                text=" means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable."
                color="black"
                appearance="light-16"
              />
            </li>
          </ul>
          <Text
            text="Collecting and Using Your Personal Data"
            color="black"
            appearance="bold-40"
          />
          <Text
            text="
          Types of Data Collected"
            color="black"
            appearance="bold-30"
          />
          <Text
            text="Personal Data
"
            color="black"
            appearance="bold-30"
          />
          <Text
            text="While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:"
            color="black"
            appearance="light-16"
          />

          <ul>
            <li>
              <Text text="Email address" appearance="light-16" color="black" />
            </li>
            <li>
              <Text
                text="First name and last name"
                appearance="light-16"
                color="black"
              />
            </li>
            <li>
              <Text text="Phone Number" appearance="light-16" color="black" />
            </li>
            <li>
              <Text
                text="Address, State, Province, ZIP/Postal code, City"
                appearance="light-16"
                color="black"
              />
            </li>
            <li>
              <Text text="Email address" appearance="light-16" color="black" />
            </li>
            <li>
              <Text text="Usage Data" appearance="light-16" color="black" />
            </li>
          </ul>

          <Text text="Usage Data" color="black" appearance="bold-40" />
          <TextField appearance="light-16" color="black">
            Usage Data is collected automatically when using the Service.
            <br />
            <br />
            Usage Data may include information such as Your Device’s Internet
            Protocol address (e.g. IP address), browser type, browser version,
            the pages of our Service that You visit, the time and date of Your
            visit, the time spent on those pages, unique device identifiers and
            other diagnostic data.
            <br />
            <br />
            When You access the Service by or through a mobile device, We may
            collect certain information automatically, including, but not
            limited to, the type of mobile device You use, Your mobile device
            unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.
            <br />
            <br />
            We may also collect information that Your browser sends whenever You
            visit our Service or when You access the Service by or through a
            mobile device.
            <br />
            <br />
          </TextField>
          <Text
            text="Tracking Technologies and Cookies"
            color="black"
            appearance="bold-40"
          />
          <TextField appearance="light-16" color="black">
            We use Cookies and similar tracking technologies to track the
            activity on Our Service and store certain information. Tracking
            technologies used are beacons, tags, and scripts to collect and
            track information and to improve and analyze Our Service. The
            technologies We use may include:
          </TextField>
          <ul>
            <li>
              <TextField appearance="light-16" color="black">
                <b>Flash Cookies. </b>Certain features of our Service may use
                local stored objects (or Flash Cookies) to collect and store
                information about Your preferences or Your activity on our
                Service. Flash Cookies are not managed by the same browser
                settings as those used for Browser Cookies. For more information
                on how You can delete Flash Cookies, please read ‘Where can I
                change the settings for disabling, or deleting local shared
                objects?’ available at
                https://helpx.adobe.com/flash-player/kb/
                disable-local-shared-objects-flash.html#
                main_Where_can_I_change_the_settings_f
                or_disablingor_deleting_local_sh
                ared_objects
              </TextField>
            </li>
            <li>
              <TextField appearance="light-16" color="black">
                <b>Web Beacons.</b> Certain sections of our Service and our
                emails may contain small electronic files known as web beacons
                (also referred to as clear gifs, pixel tags, and single-pixel
                gifs) that permit the Company, for example, to count users who
                have visited those pages or opened an email and for other
                related website statistics (for example, recording the
                popularity of a certain section and verifying system and server
                integrity).
              </TextField>
            </li>
          </ul>
          <Text
            text="Cookies can be ‘Persistent’ or ‘Session’ Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser."
            color="black"
            appearance="light-16"
          />
          <Text
            text="Cookies can be ‘Persistent’ or ‘Session’ Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser."
            color="black"
            appearance="light-16"
          />

          <ul>
            <li>
              <TextField color="black" appearance="light-16">
                <b>Necessary / Essential Cookies</b>
                <br />
                <br />
                Type: Session Cookies
                <br />
                <br />
                Administered by: Us
                <br />
                <br />
                Purpose: These Cookies are essential to provide You with
                services available through the Website and to enable You to use
                some of its features. They help to authenticate users and
                prevent fraudulent use of user accounts. Without these Cookies,
                the services that You have asked for cannot be provided, and We
                only use these Cookies to provide You with those services.
              </TextField>
            </li>
            <li>
              <TextField color="black" appearance="light-16">
                <b>Cookies Policy / Notice Acceptance Cookies</b>
                <br />
                <br />
                Type: Persistent Cookies
                <br />
                <br />
                Administered by: Us
                <br />
                <br />
                Purpose: These Cookies are essential to provide You with
                services available through the Website and to enable You to use
                some of its features. They help to authenticate users and
                prevent fraudulent use of user accounts. Without these Cookies,
                the services that You have asked for cannot be provided, and We
                only use these Cookies to provide You with those services.
              </TextField>
            </li>
            <li>
              <TextField color="black" appearance="light-16">
                <b>Functionality Cookies</b>
                <br />
                <br />
                Type: Persistent Cookies
                <br />
                <br />
                Administered by: Us
                <br />
                <br />
                Purpose: These Cookies allow us to remember choices You make
                when You use the Website, such as remembering your login details
                or language preference. The purpose of these Cookies is to
                provide You with a more personal experience and to avoid You
                having to re-enter your preferences every time You use the
                Website.
              </TextField>
            </li>
          </ul>

          <Text
            text="For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy.s"
            color="black"
            appearance="light-16"
          />
          <Text
            text="Use of Your Personal Data"
            color="black"
            appearance="bold-40"
          />
          <Text
            text="The Company may use Personal Data for the following purposes:"
            color="black"
            appearance="light-16"
          />
          <ul>
            <li>
              <TextField appearance="light-16" color="black">
                <b>To provide and maintain our Service,</b> including to monitor
                the usage of our Service.
              </TextField>
            </li>
            <li>
              <TextField appearance="light-16" color="black">
                <b>To manage Your Account:</b> to manage Your registration as a
                user of the Service. The Personal Data You provide can give You
                access to different functionalities of the Service that are
                available to You as a registered user.
              </TextField>
            </li>
            <li>
              <TextField appearance="light-16" color="black">
                <b>For the performance of a contract: </b> the development,
                compliance and undertaking of the purchase contract for the
                products, items or services You have purchased or of any other
                contract with Us through the Service.
              </TextField>
            </li>
            <li>
              <TextField appearance="light-16" color="black">
                <b>To provide You</b> with news, special offers and general
                information about other goods, services and events which we
                offer that are similar to those that you have already purchased
                or enquired about unless You have opted not to receive such
                information.
              </TextField>
            </li>
            <li>
              <TextField appearance="light-16" color="black">
                <b>To manage Your requests: </b> To attend and manage Your
                requests to Us.
              </TextField>
            </li>
            <li>
              <TextField appearance="light-16" color="black">
                <b>For business transfers:</b> We may use Your information to
                evaluate or conduct a merger, divestiture, restructuring,
                reorganization, dissolution, or other sale or transfer of some
                or all of Our assets, whether as a going concern or as part of
                bankruptcy, liquidation, or similar proceeding, in which
                Personal Data held by Us about our Service users is among the
                assets transferred.
              </TextField>
            </li>
            <li>
              <TextField appearance="light-16" color="black">
                <b>For other purposes: </b> We may use Your information for
                other purposes, such as data analysis, identifying usage trends,
                determining the effectiveness of our promotional campaigns and
                to evaluate and improve our Service, products, services,
                marketing and your experience.
              </TextField>
            </li>
          </ul>

          <Text
            text="We may share Your personal information in the following situations:"
            color="black"
            appearance="light-16"
          />
          <ul>
            <li>
              <TextField appearance="light-16" color="black">
                <b>With Service Providers: </b> We may share Your personal
                information with Service Providers to monitor and analyze the
                use of our Service, to contact You.
              </TextField>
            </li>
            <li>
              <TextField appearance="light-16" color="black">
                <b>For business transfers: </b> We may share or transfer Your
                personal information in connection with, or during negotiations
                of, any merger, sale of Company assets, financing, or
                acquisition of all or a portion of Our business to another
                company.
              </TextField>
            </li>
            <li>
              <TextField appearance="light-16" color="black">
                <b>With Affiliates: </b> We may share Your information with Our
                affiliates, in which case we will require those affiliates to
                honor this Privacy Policy. Affiliates include Our parent company
                and any other subsidiaries, joint venture partners or other
                companies that We control or that are under common control with
                Us.
              </TextField>
            </li>
            <li>
              <TextField appearance="light-16" color="black">
                <b>With business partners: </b>We may share Your information
                with Our business partners to offer You certain products,
                services or promotions.
              </TextField>
            </li>
            <li>
              <TextField appearance="light-16" color="black">
                <b>With other users: </b>when You share personal information or
                otherwise interact in the public areas with other users, such
                information may be viewed by all users and may be publicly
                distributed outside. If You interact with other users or
                register through a Third-Party Social Media Service, Your
                contacts on the Third-Party Social Media Service may see Your
                name, profile, pictures and description of Your activity.
                Similarly, other users will be able to view descriptions of Your
                activity, communicate with You and view Your profile.
              </TextField>
            </li>
            <li>
              <TextField appearance="light-16" color="black">
                <b>With Your consent: </b>We may disclose Your personal
                information for any other purpose with Your consent.
              </TextField>
            </li>
          </ul>
          <Text text="With Your consent: " color="black" appearance="bold-30" />
          <TextField appearance="light-16" color="black">
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies.
            <br />
            <br />
            The Company will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to
            improve the functionality of Our Service, or We are legally
            obligated to retain this data for longer time periods.
          </TextField>
          <Text
            text="Transfer of Your Personal Data"
            color="black"
            appearance="bold-30"
          />
          <TextField appearance="light-16" color="black">
            Your information, including Personal Data, is processed at the
            Company’s operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to — and maintained on — computers
            located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction.
            <br />
            <br />
            Your consent to this Privacy Policy followed by Your submission of
            such information represents Your agreement to that transfer.
            <br />
            <br />
            The Company will take all steps reasonably necessary to ensure that
            Your data is treated securely and in accordance with this Privacy
            Policy and no transfer of Your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of Your data and other personal
            information.
          </TextField>
          <Text
            text="Disclosure of Your Personal Data"
            color="black"
            appearance="bold-40"
          />
          <Text
            text="Business Transactions"
            color="black"
            appearance="bold-30"
          />
          <Text
            text="If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy."
            color="black"
            appearance="light-16"
          />
          <Text text="Law enforcement" color="black" appearance="bold-30" />
          <Text
            text="Other legal requirements"
            color="black"
            appearance="bold-30"
          />
          <Text
            text="The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:"
            color="black"
            appearance="light-16"
          />

          <ul>
            <li>
              <Text
                text="Comply with a legal obligation"
                color="black"
                appearance="light-16"
              />
              <Text
                text="Protect and defend the rights or property of the Company"
                color="black"
                appearance="light-16"
              />
              <Text
                text="Prevent or investigate possible wrongdoing in connection with the Service"
                color="black"
                appearance="light-16"
              />
              <Text
                text="Protect the personal safety of Users of the Service or the public"
                color="black"
                appearance="light-16"
              />
              <Text
                text="Protect against legal liability"
                color="black"
                appearance="light-16"
              />
            </li>
          </ul>

          <Text
            text="Security of Your Personal Data"
            color="black"
            appearance="bold-30"
          />
          <Text
            text="The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security."
            color="black"
            appearance="light-16"
          />
          <Text text="Children’s Privacy" color="black" appearance="bold-30" />
          <TextField appearance="light-16" color="black">
            Our Service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from anyone
            under the age of 13. If You are a parent or guardian and You are
            aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data
            from anyone under the age of 13 without verification of parental
            consent, We take steps to remove that information from Our servers.
            <br />
            <br />
            If We need to rely on consent as a legal basis for processing Your
            information and Your country requires consent from a parent, We may
            require Your parent’s consent before We collect and use that
            information.
          </TextField>
          <Text
            text="Links to Other Websites"
            color="black"
            appearance="bold-30"
          />
          <TextField color="black" appearance="light-16">
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third party link, You will be
            directed to that third party’s site. We strongly advise You to
            review the Privacy Policy of every site You visit.
            <br />
            <br />
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </TextField>
          <Text
            text="Changes to this Privacy Policy"
            color="black"
            appearance="bold-30"
          />
          <TextField color="black" appearance="light-16">
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
            <br />
            <br />
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the ‘Last
            updated’ date at the top of this Privacy Policy.
            <br />
            <br />
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </TextField>
          <Text text="Contact Us" color="black" appearance="bold-30" />
          <Text
            text="If you have any questions about this Privacy Policy, You can contact us:  "
            color="black"
            appearance="light-16"
          />
          <ul>
            <li>By email: hello@zfwhospitality.com</li>
          </ul>
          <Text text="" color="black" appearance="light-16" />
        </div>
      
      </div>
    </>
  );
};

export default privacyPolicy;
