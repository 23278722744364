import React from "react";

const TextField = ({ appearance, color, styles, children }) => {
  return (
    <span className={`Text ${appearance} ${color}`} style={styles}>
      {children}
    </span>
  );
};

export default TextField;
