import Text from "../../CommonComponents/text";
import "./termAndCondition.css";
import React from "react";

const TermAndCondition = () => {
  return (
    <>
      <div className="termAndCondition-page">
        <Text text="Terms & Condition" appearance="light-50" color="black" />
        <div className="term-and-condition-container">
          <Text
            text="These terms and conditions outline the rules and regulations for the use of ZFW Hospitality Private Limited’s Website, located at zippee.delivery."
            color="black"
          />
          <Text
            color="black"
            text="By accessing this website we assume you accept these terms and conditions. Do not continue to use zippee.delivery if you do not agree to take all of the terms and conditions stated on this page."
          />

          <Text
            text="The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: “Client”, “You” and “Your” refers to you, the person log on this website and compliant to the Company’s terms and conditions. “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers to our Company. “Party”, “Parties”, or “Us”, refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services, in accordance with and subject to, prevailing law of India. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same."
            color="black"
          />
          <Text
            color="black"
            text="Services provided by us are not returnable & non refundable and Cancellation is not applicable"
          />
          <Text text="Cookies" color="black" appearance="light-40" />

          <Text
            color="black"
            text="We employ the use of cookies. By accessing zippee.delivery, you agreed to use cookies in agreement with the ZFW Hospitality Private Limited’s Privacy Policy."
          />

          <Text
            color="black"
            text="Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies."
          />

          <Text text="License" color="black" appearance="light-40" />

          <Text
            text="Unless otherwise stated, ZFW Hospitality Private Limited and/or its licensors own the intellectual property rights for all material on zippee.delivery. All intellectual property rights are reserved. You may access this from zippee.delivery for your own personal use subjected to restrictions set in these terms and conditions."
            color="black"
          />

          <Text text="You must not:" color="black" />

          <ul>
            <li>
              <Text
                text="Republish material from zippee.delivery"
                color="black"
              />
            </li>
            <li>
              <Text
                text="Sell, rent or sub-license material from zippee.delivery"
                color="black"
              />
            </li>
            <li>
              <Text
                text="Reproduce, duplicate or copy material from zippee.delivery"
                color="black"
              />
            </li>
            <li>
              <Text
                text="Redistribute content from zippee.delivery"
                color="black"
              />
            </li>
          </ul>

          <Text
            text="Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. ZFW Hospitality Private Limited does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions ofZFW Hospitality Private Limited, its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, ZFW Hospitality Private Limited shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website."
            color="black"
          />

          <Text
            text="ZFW Hospitality Private Limited reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions."
            color="black"
          />

          <Text text="You warrant and represent that:" color="black" />

          <ul>
            <li>
              <Text
                text="You are entitled to post the Comments on our website and have all necessary licenses and consents to do so"
                color="black"
              />
            </li>
            <li>
              <Text
                text="The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party"
                color="black"
              />
            </li>
            <li>
              <Text
                text="The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy"
                color="black"
              />
            </li>
            <li>
              <Text
                text="The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity."
                color="black"
              />
            </li>
          </ul>
          <Text
            text="You hereby grant ZFW Hospitality Private Limited a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media."
            color="black"
          />

          <Text
            text="Hyperlinking to our Content"
            color="black"
            appearance="light-40"
          />

          <Text
            color="black"
            text="The following organizations may link to our Website without prior written approval:"
          />

          <ul>
            <li>
              <Text text="Government agencies;" color="black" />
            </li>
            <li>
              <Text text="Search engines" color="black" />
            </li>
            <li>
              <Text text="News organizations" color="black" />
            </li>
            <li>
              <Text
                text="Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses "
                color="black"
              />
            </li>
            <li>
              <Text
                text="System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site."
                color="black"
              />
            </li>
          </ul>

          <Text
            color="black"
            text="These organizations may link to our home page, to publications or to other Website information so long as the link:"
          />

          <ul>
            <li>
              <Text text="is not in any way deceptive;" color="black" />
            </li>
            <li>
              <Text
                text="fits within the context of the linking party’s site."
                color="black"
              />
            </li>
          </ul>

          <Text
            text="We may consider and approve other link requests from the following types of organizations:"
            color="black"
          />

          <ul>
            <li>
              <Text
                text="commonly-known consumer and/or business information sources;"
                color="black"
              />
            </li>
            <li>
              <Text text="dot.com community sites;" color="black" />
            </li>
            <li>
              <Text
                text="associations or other groups representing charities;"
                color="black"
              />
            </li>
            <li>
              <Text text="online directory distributors;" color="black" />
            </li>
            <li>
              <Text text="internet portals;" color="black" />
            </li>
            <li>
              <Text
                text="accounting, law and consulting firms; and"
                color="black"
              />
            </li>
            <li>
              <Text
                text="educational institutions and trade associations."
                color="black"
              />
            </li>
          </ul>

          <Text
            color="black"
            text="We will approve link requests from these organizations if we decide that:"
          />

          <ul>
            <li>
              <Text
                text="the link would not make us look unfavorably to ourselves or to our accredited businesses;"
                color="black"
              />
            </li>

            <li>
              <Text
                text="the organization does not have any negative records with us;"
                color="black"
              />
            </li>

            <li>
              <Text
                text="the benefit to us from the visibility of the hyperlink compensates the absence of ZFW Hospitality Private Limited; and"
                color="black"
              />
            </li>

            <li>
              <Text
                text="the link is in the context of general resource information."
                color="black"
              />
            </li>
          </ul>

          <Text
            color="black"
            text="These organizations may link to our home page so long as the link:"
          />

          <ul>
            <li>
              <Text text="is not in any way deceptive;" color="black" />
              <Text
                text="does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and"
                color="black"
              />
              <Text
                text="fits within the context of the linking party’s site."
                color="black"
              />
            </li>
          </ul>
          <Text
            text="If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to ZFW Hospitality Private Limited. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response."
            color="black"
          />
          <Text
            text="Approved organizations may hyperlink to our Website as follows:"
            color="black"
          />
          <ul>
            <li>
              <Text text="By use of our corporate name; or" color="black" />
            </li>
            <li>
              <Text
                text="By use of the uniform resource locator being linked to; or"
                color="black"
              />
            </li>
            <li>
              <Text
                text="By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party’s site."
                color="black"
              />
            </li>
          </ul>
          <Text
            text="No use of ZFW Hospitality Private Limited’s logo or other artwork will be allowed for linking absent a trademark license agreement."
            color="black"
          />

          <Text color="black" text="iFrames" appearance="light-40" />

          <Text
            color="black"
            text="Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website."
          />

          <Text color="black" text="Content Liability" appearance="light-40" />
          <Text
            color="black"
            text="We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights."
          />
          <Text color="black" text="Your Privacy" appearance="light-40" />

          <Text color="black" text="Please read Privacy Policy" />

          <Text
            color="black"
            text="Reservation of Rights"
            appearance="light-40"
          />

          <Text
            color="black"
            text="We reserve the right to reLoginquest that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amen these terms and conditions and it’s linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions."
          />

          <Text color="black" text="Removal of links from our website" />

          <Text
            color="black"
            text="If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly."
          />

          <Text
            color="black"
            text="We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date."
          />

          <Text color="black" text="Disclaimer" appearance="light-40" />

          <Text
            color="black"
            text="To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:"
          />

          <ul>
            <li>
              <Text
                text="limit or exclude our or your liability for death or personal injury;"
                color="black"
              />
            </li>

            <li>
              <Text
                text="limit or exclude our or your liability for fraud or fraudulent misrepresentation;"
                color="black"
              />
            </li>
            <li>
              <Text
                text="limit any of our or your liabilities in any way that is not permitted under applicable law; or"
                color="black"
              />
            </li>
            <li>
              <Text
                text="exclude any of our or your liabilities that may not be excluded under applicable law."
                color="black"
              />
            </li>
          </ul>

          <Text
            color="black"
            text="The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer:"
          />
          <ul>
            <li>
              <Text
                text="are subject to the preceding paragraph; and"
                color="black"
              />
            </li>
            <li>
              <Text
                text="govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty."
                color="black"
              />
            </li>
          </ul>
          <Text
            text="As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature."
            color="black"
          />
          <ul>
            <li>
              <Text
                text="This platform is Managed by ZFW Hospitality Pvt. Ltd"
                color="black"
              />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default TermAndCondition;
