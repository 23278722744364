import React from "react";
import Text from "../../../CommonComponents/text";
import ProfileCard from "../../../Components/Cards/profile_card";
import "../about.css";

const Team = () => {
  const teamInfo = [
    {
      name: "Madhav Kasturia",
      priority: "1",
      image_url:
        "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/team/1_MadhavKasturia(Founder).jpg",
      job_description: "Founder",
    },
    {
      name: "Angad Singh",
      priority: "2",
      image_url:
        "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/team/angad-singh-zippee-new.jpeg",
      job_description: "Founding Team",
    },

    {
      name: "Mohit Singh",
      priority: "7",
      image_url:
        "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/team/7_MohitSingh(Product&Tech).jpg",
      job_description: "Product & Tech",
    },
    {
      name: "Bhanu Dixit",
      priority: "8",
      image_url:
        "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/team/bhanu_dixit_tech_lead.png",
      job_description: "Technology",
    },
    {
      name: "Pravir Kalra",
      priority: "8",
      image_url:
        "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/team/pravir-kalra.jpeg",
      job_description: "Growth",
    },
    {
      name: "Sumit Patodia",
      priority: "17",
      image_url:
        "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/team/17_SumitPatodia(Operations-South).jpeg",
      job_description: "Operations",
    },
    {
      name: "Musaib Bhatkar",
      priority: "4",
      image_url:
        "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/team/4_MusaibBhatkar(Operations).jpg",
      job_description: "Operations",
    },
    {
      name: "Ansh Gupta",
      priority: "11",
      image_url:
        "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/team/11_AnshGupta(Founder\u2019sOffice).jpeg",
      job_description: "Founder's Office",
    },
    // {
    //   name: "Nikhil Ail",
    //   priority: "3",
    //   image_url:
    //     "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/team/3_NikhilAil(Operations).jpg",
    //   job_description: "Operations",
    // },

    // {
    //   name: "Prince K",
    //   priority: "5",
    //   image_url:
    //     "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/team/5_PrinceK(Operations).jpg",
    //   job_description: "Operations",
    // },
    {
      name: "Siddharth Saini",
      priority: "6",
      image_url:
        "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/team/6_SiddharthSaini(Partnerships).jpg",
      job_description: "Partnerships",
    },
    {
      name: "Ankit Tiwary",
      priority: "8",
      image_url:
        "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/team/8_AnkitTiwary(Operations).jpg",
      job_description: "Operations",
    },
    {
      name: "Nikhil Singh",
      priority: "9",
      image_url:
        "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/team/9_NikhilSingh(Operation).png",
      job_description: "Operations",
    },
    {
      name: "Harshit Jain",
      priority: "12",
      image_url:
        "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/team/12_HarshitJain(Operations).jpeg",
      job_description: "Operations",
    },
    {
      name: "Priyanka K",
      priority: "13",
      image_url:
        "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/team/13_PriyankaK(CustomerSuccess).jpg",
      job_description: "Customer Success",
    },
    {
      name: "Ashish Dwivedi",
      priority: "16",
      image_url:
        "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/team/16_AshishDwivedi(Partnerships).jpg",
      job_description: "Partnerships",
    },

    {
      name: "Aniket Dubey",
      priority: "21",
      image_url:
        "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/team/21_AniketDubey(Technology).jpeg",
      job_description: "Technology",
    },
    {
      name: "Shishir Kumar",
      priority: "22",
      image_url:
        "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/team/22_ShishirKumar(Operations).jpg",
      job_description: "Account Manager",
    },
    {
      name: "Simerpreet",
      priority: "24",
      image_url:
        "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/team/simar_test.png",
      job_description: "Customer Success",
    },
    // {
    //   name: "Dhivyabalan",
    //   priority: "25",
    //   image_url:
    //     "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/team/balan_test.png",
    //   job_description: "Operations",
    // },
    {
      name: "Vikram Singh",
      priority: "18",
      image_url:
        "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/team/18_VikramSingh(Finance).jpg",
      job_description: "Finance",
    },
    {
      name: "Sourabh Siwach",
      priority: "10",
      image_url:
        "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/team/10_SourabhSiwach(Finance).jpg",
      job_description: "Finance",
    },
  ];

  return (
    <div className="container">
      <Text text="Our Team" appearance="bold-40" color="black" />

      <div className="teams-profile">
        {teamInfo.map((item) => (
          <ProfileCard
            key={item["priority"]}
            name={item["name"]}
            job_title={item["job_description"]}
            img_src={item["image_url"]}
          />
        ))}
      </div>
    </div>
  );
};

export default Team;
