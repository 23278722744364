import React from "react";
import Text from "../../CommonComponents/text";
import "./cards.css";
import Lottie from "lottie-react";

const LottieCard = ({
  title,
  description,
  width,
  height,
  animation_src,
  text_color,
  title_appearance,
  description_appearance,
}) => {
  const containerStyle = {
    height: height,
    width: width,
  };

  return (
    <div>
      <div className="card">
        <div style={containerStyle}>
          <Lottie animationData={animation_src} />
        </div>
        <div className={`card-container`}>
          <Text text={title} color={text_color} appearance={title_appearance} />
          <Text
            text={description}
            color={text_color}
            appearance={description_appearance}
          />
        </div>
      </div>
    </div>
  );
};

export default LottieCard;
