import React from "react";
import Text from "../../../CommonComponents/text";
import Tilt from "react-parallax-tilt";
import "../about.css";
import investors from "../../../helpers/investors";
import InvestorCards from "../../../Components/Cards/investorCards";

const Investors = () => {
  return (
    <div className="container">
      <Text text="Backed By The Best" appearance="bold-40" color="black" />
      <div className="investor-cards">
        {investors.map((item, index) => (
          <Tilt className="tilt-card">
            <InvestorCards
              key={index}
              s
              name={item["name"]}
              company_name={item["company"]}
              img_src={item["image_url"]}
            />
          </Tilt>
        ))}
      </div>
    </div>
  );
};

export default Investors;
