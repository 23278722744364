import React from "react";
import Text from "../../../CommonComponents/text";
import images from "../../../helpers/ImportImages";

const IntegratedTools = () => {
  const IntegratedTools = [
    "Shopify.png",
    "Loginext.png",
    "Easyecom.png",
    "Zomato.png",
    // "Pickrr.png",
    "Unicommerce.png",
    "Shiprocket.jpg",
    "Swiggy.png",
    "WooCommerce.png",
    "Thrive.png",
    "amazon.png",
  ];

  return (
    <>
      <div className="home-tools-container">
        <div className="home-tools-text-container">
          <Text
            color="black"
            appearance="bold-40"
            text="Over 100+ Seamless E-Commerce Integrations"
            style={{
              textAlign: "center",
            }}
          />
          <Text
            color="black"
            appearance="light-20"
            text="We integrate with all major E-commerce tools and platforms to ensure Streamlined Fulfillment right from your online website to your customer’s door."
          />
        </div>
        <div className="home-tools-icons-container">
          {IntegratedTools.map((image, index) => (
            <img src={images[image]} alt="" key={index} />
          ))}
        </div>
      </div>
    </>
  );
};

export default IntegratedTools;
