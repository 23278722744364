import React from "react";
import "./commonComponents.css";

const CustomButton = ({ type, variant, onClick, id, styles, children }) => {
  return (
    <button
      type={type && "click"}
      className={`Button ${variant}`}
      onClick={onClick}
      style={styles}
      id={id}
    >
      {children}
    </button>
  );
};
export default CustomButton;
