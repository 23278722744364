import React, { useState } from "react";
import Text from "../../CommonComponents/text";
import "./cards.css";

const InvestorCards = ({ name, company_name, img_src }) => {
  const [hover, setHover] = useState(false);

  const img_style = {
    backgroundImage: `url("${img_src}`,
    // filter: "grayscale(100%)",
  };
  const img_hover_style = {
    backgroundImage: `url("${img_src}`,
    // filter: "grayscale(0%)",
  };
  const title_styles = {
    backgroundColor: "black",
    width: "fit-content",
    padding: "0px 25px",
    borderRadius: "5px",
  };
  const text_styles = {
    fontSize: "14px",
    fontWeight: "600",
  };

  return (
    <div>
      <div
        className="investor-card-container"
        style={hover ? img_hover_style : img_style}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div className="layer">
          <div className="overlay">
            <Text
              text={name}
              color="white"
              appearance="bold-16"
              styles={title_styles}
            />
            <Text
              text={company_name}
              color="white"
              appearance=""
              styles={text_styles}
            />
          </div>
        </div>
        {/* <img src={img_src}  alt='avatar' className="investor-image image-gray-effect" /> */}
      </div>
    </div>
  );
};

export default InvestorCards;
