import React, { useEffect } from "react";
import Text from "../../CommonComponents/text";
import "./home.css";
// import Button from "../../CommonComponents/button";
import CustomButton from "../../CommonComponents/CustomButton";
import TextField from "../../CommonComponents/textfield";
import Title from "./components/title";
import Companies from "./components/companies";
import HowItWorks from "./components/howItWorks";
import Fulfilment from "./components/fulfilment";
import Stats from "./components/stats";
import IntegratedTools from "./components/integratedTools";
import FeaturedIn from "./components/featuredIn";
import { Paper } from "@mui/material";
import { TypeAnimation } from "react-type-animation";
import { Link } from "react-router-dom";

const Home = () => {
  useEffect(() => {
    const header = document.querySelector(".navbar");
    header.classList.remove("hide");

    const footer = document.querySelector(".footer");
    footer.classList.remove("hide");

    // const chat = document.querySelector(".Whatsapp-Button-Container");
    // chat.classList.remove("hide");

    const body = document.body;
    body.classList.remove("error-page-body");
  }, []);

  return (
    <>
      <div className="home-page">
        <div className="home-page-container">
          <Title />
          <Companies />
          <HowItWorks />
          <Fulfilment />
          <Stats />
          <IntegratedTools />
          <FeaturedIn />

          <Paper elevation={6}>
            <div className="home-request-container">
              <div className="home-signup-title">
                <Text
                  color="black"
                  appearance="bold-30"
                  text="Sign Up Today to Create Powerful "
                />
                <TypeAnimation
                  sequence={["", 2000, "Customer Experiences ", 2000]}
                  wrapper="div"
                  cursor={true}
                  deletionSpeed={{ type: "keyStrokeDelayInMs", value: 20 }}
                  speed={{ type: "keyStrokeDelayInMs", value: 20 }}
                  repeat={Infinity}
                  style={{
                    color: "#F4C244",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  className="bold-30"
                />
              </div>
              <Text
                color="black"
                appearance="light-20"
                text="Your customers want a better delivery experience. Use Same-Day Delivery & stay ahead of the curve. Get started today!"
              />

              <Link to="/contact-us">
                <CustomButton variant="primary">
                  <TextField color="black" appearance="bold-20">
                    REQUEST A QUOTE
                  </TextField>
                </CustomButton>
              </Link>
            </div>
          </Paper>
        </div>
      </div>
    </>
  );
};

export default Home;
