import { useState } from "react";
import { default as ax } from "axios";

const axios = ax.create({
  baseURL: process.env.REACT_APP_BASE_URL + "/api/1",
  headers: {
    // "x-api-key": "xeJJzhaj1mQ-ksTB_nF_iH0z5YdG50yQtwQCzbcHuKA",
    "Content-Type": "application/json",
  },
});

export const useAxios = () => {
  const [response, setResponse] = useState(undefined);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const operation = async (params) => {
    try {
      setLoading(true);
      const result = await axios.request(params);
      setResponse(result.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return { response, error, loading, operation };
};

export default useAxios;
