import { React, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./navbar.css";
import Button from "../../CommonComponents/button";
import Text from "../../CommonComponents/text";
import images from "../../helpers/ImportImages";

const Navbar = () => {
  const [isOpened, setIsOpened] = useState(false);
  const activePage = useLocation().pathname;

  const toggleMenu = () => {
    setIsOpened(!isOpened);
  };

  return (
    <>
      <div className="navbar">
        <Link to="/">
          <img className="logo" src={images["Zippee-Logo.png"]} alt="" />
        </Link>
        <div className="navbar-links">
          <ul className="child-navbar-links">
            <li>
              <Link to="/about">
                <Text
                  text="ABOUT US"
                  appearance="bold-20 navlinks-text-hover-color"
                  color={activePage === "/about" ? "secondary-color" : "white"}
                />
              </Link>
            </li>
            <li className="dropdown" onClick={toggleMenu}>
              <span className="dropbtn">
                <Text
                  text="RESOURCES"
                  appearance="bold-20 navlinks-text-hover-color"
                  color={
                    activePage === "/news" || activePage === "/blogs"
                      ? "secondary-color"
                      : "white"
                  }
                />
              </span>
              <div className="dropdown-content">
                <Link to="/news">
                  <Text
                    text="NEWS"
                    appearance="bold-20 navlinks-text-hover-color"
                    color={activePage === "/news" ? "secondary-color" : "white"}
                  />
                </Link>
                <Link to="/blogs">
                  <Text
                    text="BLOGS"
                    appearance="bold-20 navlinks-text-hover-color"
                    color={
                      activePage === "/blogs" ? "secondary-color" : "white"
                    }
                  />
                </Link>
              </div>
            </li>
            <li>
              <Link
                to="https://zfwindia.notion.site/ZFW-Careers-06c01cf4e6cb48adac0bf98360346805"
                target="_blank"
              >
                <Text
                  text="CAREERS"
                  appearance="bold-20 navlinks-text-hover-color"
                  color={
                    activePage === "/careers" ? "secondary-color" : "white"
                  }
                />
              </Link>
            </li>
          </ul>
          <ul>
            {/* <li>
              <Link to="/track-order">
                <Button text="TRACK ORDER" variant="secondary" />
              </Link>
            </li> */}
            <li>
              <Link to="/contact-us">
                <Button text="CONTACT US" variant="secondary" />
              </Link>
            </li>
            <li>
              <a
                href="https://blaze.zippee.delivery/sign-in"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button text="LOGIN" variant="secondary" />
              </a>
            </li>
          </ul>
        </div>
        <label htmlFor="nav-toggle" className="nav-toggle-label">
          <span
            className={`menu ${isOpened ? "opened" : ""}`}
            onClick={toggleMenu}
            aria-label="Main Menu"
          >
            <svg viewBox="0 0 100 100">
              <path
                className="line line1"
                d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
              />
              <path className="line line2" d="M 20,50 H 80" />
              <path
                className="line line3"
                d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
              />
            </svg>
          </span>
        </label>
      </div>
      <div
        className={`navbar-menu ${
          isOpened ? "navbar-menu-show" : "navbar-menu-hide"
        }`}
      >
        <nav>
          <ul>
            <li onClick={toggleMenu}>
              <Link to="/about">
                <Text
                  text="ABOUT US"
                  appearance="bold-30 text-hover-effect"
                  color={activePage === "/about" ? "secondary-color" : "white"}
                />
              </Link>
            </li>
            <li onClick={toggleMenu}>
              <Link to="/news">
                <Text
                  text="NEWS"
                  appearance="bold-30 text-hover-effect"
                  color={
                    activePage === "/resources" ? "secondary-color" : "white"
                  }
                />
              </Link>
            </li>
            <li onClick={toggleMenu}>
              <Link to="/blogs">
                <Text
                  text="BLOGS"
                  appearance="bold-30 text-hover-effect"
                  color={activePage === "/blogs" ? "secondary-color" : "white"}
                />
              </Link>
            </li>
            <li onClick={toggleMenu}>
              <Link
                to="https://zfwindia.notion.site/ZFW-Careers-06c01cf4e6cb48adac0bf98360346805"
                target="_blank"
              >
                <Text
                  text="CAREERS"
                  appearance="bold-30 text-hover-effect"
                  color={
                    activePage === "/careers" ? "secondary-color" : "white"
                  }
                />
              </Link>
            </li>
          </ul>
          <ul>
            {/* <li onClick={toggleMenu}>
              <Link to="/track-order">
                <Button text="TRACK ORDER" variant="secondary" />
              </Link>
            </li> */}
            <li onClick={toggleMenu}>
              <Link to="/contact-us">
                <Button text="CONTACT US" variant="secondary" />
              </Link>
            </li>
            <li onClick={toggleMenu}>
              <Link to={process.env.REACT_APP_BASE_LOGIN_URL} target="_blank">
                <Button text="LOGIN" variant="secondary" />
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
