import React from "react";
import Text from "../../../CommonComponents/text";
import { useNavigate } from "react-router-dom";

const FeaturedIn = () => {
  const navigate = useNavigate();
  const FeaturedIn = [
    "Outlook.png",
    "Yahoo-news.png",
    "Bloomberg.jpeg",
    "Inc42.png",
    "EtFeatured.png",
    "Hindustan_Times.png",
    "Yourstory2.png",
  ];
  const base_image_url =
    "https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/news_outlets/";

  return (
    <>
      <div className="home-featured-container">
        <Text color="black" appearance="bold-30" text="As Featured in" />
        <div className="home-featured-icon-container">
          {FeaturedIn.map((image, index) => (
            <img
              src={base_image_url + image}
              key={index}
              alt=""
              onClick={() => navigate("/news")}
              style={{ cursor: "pointer" }}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default FeaturedIn;
