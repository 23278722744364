import React from "react";
import Text from "../../CommonComponents/text";
import ".././PrivacyPolicy/privacyPolicy.css";
import TextField from "../../CommonComponents/textfield";

const refundPolicy = () => {
  return (
    <>
      <div className="privacy-policy-page">
        
        <Text text="Refund Policy" color="black" appearance="bold-50" />
        <div className="term-and-condition-container">
          
          <Text
            color="black"
            text="Services provided by us are not returnable & non refundable and Cancellation is not applicable."          
          />
          <div style={{height: "20vh"}}></div>
        </div>
      
      </div>
    </>
  );
};

export default refundPolicy;
