import React from "react";
import Text from "../../CommonComponents/text";
import Articles from "./components/articles";
import "./resources.css";
import data from "../../assets/json_files/resource_page.json";
import { Link } from "react-router-dom";

const Resources = () => {
  return (
    <>
      <div className="resource-page">
        <div id="resource-page-title">
          <Text text="Resources" appearance="light-60" color="black" />
          <br />
          <Text
            text="For Press & Media inquiries, please contact: madhav@zfwhospitality.com"
            color="black"
          />
        </div>

        <div className="article-cards">
          {data.map((item, index) => (
            <Link to={item.link} target="_blank">
              <Articles
                date={item.date}
                website_name={item.website_name}
                img_src={item.img_src}
                alt="https://zippee-delivery-assets.s3.ap-south-1.amazonaws.com/resource_page_images/With_offices_sealed,_interns_find_new_ways_to_learn_the_ropes.jpg"
                title={item.title}
                link={item.link}
              />
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default Resources;
