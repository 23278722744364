import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "../../../CommonComponents/button";
import { Alert } from "@mui/material";
import axios from "axios";

export default function FormComponent(props) {
  const [formData, setFormData] = useState({
    name: "",
    brand: "",
    phone: "",
    email: props.data,
    orders: "",
    error: "",
    submitted: false,
  });

  const validateForm = () => {
    const { name, brand, phone, email, orders } = formData;

    if (!name) {
      return "Please enter a valid name";
    }

    if (!brand) {
      return "Please enter a valid brand name";
    }
    if (!email) {
      return "Please enter a valid email address";
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      return "Please enter a valid email address";
    }

    if (phone.length !== 10) {
      return "Please enter the valid phone number";
    }

    if (
      !/^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i.test(
        phone
      )
    ) {
      return "Please enter a valid phone number";
    }

    if (!orders) {
      return "Please fill out the number of orders";
    }
    if (typeof orders === "number" && !isNaN(orders)) {
      return "Please enter a valid number of orders";
    }

    return ""; // Return empty string if no errors
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const error = validateForm();

    if (error === "") {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://api.zippee.delivery/api/1/contact-us/",
        headers: {
          "x-api-key": "xeJJzhaj1mQ-ksTB_nF_iH0z5YdG50yQtwQCzbcHuKA",
          "Content-Type": "application/json",
          // "Access-Control-Allow-Origin": "https://api.zippee.delivery",
          // "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
        },
        data: {
          name: formData.name,
          brand: formData.brand,
          phone: formData.phone,
          email: props.data ? props.data : formData.email,
          orders: formData.orders,
        },
      };

      axios
        .request(config)

        .then((response) => {
          setFormData((prevState) => ({
            ...prevState,
            submitted: true,
            error: "",
          }));
          // Handle success scenario
        })
        .catch((error) => {
          setFormData((prevState) => ({
            ...prevState,
            error: "Something went wrong. Please try again.",
          }));
          // Handle error scenario
        });
    } else {
      setFormData((prevState) => ({
        ...prevState,
        error: error,
        submitted: false,
      }));
    }
  };

  return (
    <form id="home-page-request-access-popup" onSubmit={handleSubmit}>
      {formData.error && <Alert severity="error">{formData.error}</Alert>}
      {formData.submitted && !formData.error && (
        <Alert severity="success">
          Thank you for your message. It has been submitted successfully.
        </Alert>
      )}
      <label>
        Name*
        <TextField
          className="contact-us-input"
          variant="outlined"
          size="small"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
        />
      </label>

      <label>
        Brand Name*
        <TextField
          className="contact-us-input"
          variant="outlined"
          size="small"
          name="brand"
          value={formData.brand}
          onChange={handleInputChange}
        />
      </label>
      {/* <label>
        Email*
        <TextField
          className="contact-us-input"
          variant="outlined"
          size="small"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
        />
      </label> */}
      <label>
        Phone Number*
        <TextField
          className="contact-us-input"
          variant="outlined"
          size="small"
          name="phone"
          value={formData.phone}
          onChange={handleInputChange}
        />
      </label>
      <label>
        No. of Website order per month*
        <TextField
          className="contact-us-input"
          variant="outlined"
          size="small"
          name="orders"
          value={formData.orders}
          onChange={handleInputChange}
        />
      </label>
      <Button type="submit" variant="primary" text="Submit" />
    </form>
  );
}
