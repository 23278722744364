import React from "react";
import Text from "../../../CommonComponents/text";
import "../about.css";
import images from "../../../helpers/ImportImages";
import TextField from "../../../CommonComponents/textfield";

const Description = () => {
  return (
    <div className="container">
      <Text text="Meet Zippee" color="black" appearance="bold-40" />
      <div className="description-container">
        <img src={images["MeetZippee.jpg"]} alt="" />
        <div className="description">
          <ul>
            <li>
              {/* <Text text="We enable Same-Day Deliveries for the most customer-centric E-commerce brands through our next-gen fulfillment platform and network of dark stores across India." /> */}
              <TextField color="black" appearance="light-20">
                We enable <b>Quick Delivery</b> for the most customer-centric
                Ecommerce brands & marketplaces-{" "}
                <b color="black">
                via our network of dark stores & last-mile fleets{" "}
                </b>
                across India.
              </TextField>
            </li>
            <li>
              <TextField appearance="light-20" color="black">
              Brands connect their online store to our platform in just a few clicks & can <b>{"kickstart 2-Hour/Same-Day Delivery"}</b> for consumers.
              </TextField>
            </li>
            <li>
              <Text
                color="black"
                appearance="light-20"
                text="This approach helps brands significantly improve customer loyalty, fulfillment costs, & RTOs by up to 90%."
              />
            </li>
          </ul>
          <div>
            <TextField color="black" appearance="light-20">
              <b style={{ color: "#E5C244" }}>Our Mission: </b>
              To democratize Amazon/zepto grade logistics for 100,000+ brands in India that want to sell more through their own channels, have greater control over customers, & offer a comparable rapid delivery experience to customers.
            </TextField>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Description;
