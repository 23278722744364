import { useEffect, useState } from "react";
import "../Blogs/blogs.css";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import CustomCarousel from "../../Components/Carousel/Carousel";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import SearchIcon from "@mui/icons-material/Search";
import useAxios from "../../customHooks/useAxios";
import { Link } from "react-router-dom";
import { BsInstagram } from "react-icons/bs";
import { RiWhatsappFill } from "react-icons/ri";
import { FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { TypeAnimation } from "react-type-animation";
import Text from "../../CommonComponents/text";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CancelIcon from "@mui/icons-material/Cancel";
import FormComponent from "../ContactUs/Components/FormComponents";

const Blogs = () => {
  const [currentPage, setCurrentPage] = useState(1); // Start with page 1
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 20; // Assuming page size is constant

  const {
    response: blogResponse,
    loading: blogLoading,
    error: blogError,
    operation: blogOperation,
  } = useAxios();
  const {
    response: titleResponse,
    loading: titleLoading,
    error: titleError,
    operation: titleOperation,
  } = useAxios();
  const {
    response: categoryResponse,
    loading: categoryLoading,
    error: categoryError,
    operation: categoryOperation,
  } = useAxios();
  const [open, setOpen] = useState(false);
  const [mail, setMail] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [msg, setMsg] = useState("");

  const validateEmail = (input) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(input);
  };

  const handleClickOpen = () => {
    if (mail !== "" && mail !== null) {
      if (validateEmail(mail) === true) {
        setOpen(true);
      } else {
        setInvalid(true);
        setMsg("The e-mail address entered is invalid.");
      }
    } else {
      setInvalid(true);
      setMsg("The field is required.");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const listIconStyle = {
    color: "#F4C244",
    cursor: "pointer",
  };

  const getBlog = (data) => {
    blogOperation({
      method: "get",
      url: "/blogs/",
      params: {
        slug: data ? data.slug : null,
      },
    });
  };

  const searchBlogs = (data) => {
    titleOperation({
      method: "get",
      url: "/blogs/",
      params: {
        title: data,
        slug: data ? data.slug : null,
        category_id: data ? data.id : null,
      },
    });
  };

  useEffect(() => {
    blogOperation({
      method: "get",
      url: "/blogs",
      params: {
        page: currentPage,
        page_size: pageSize,
      },
    });

    categoryOperation({
      method: "get",
      url: "/blogs-category",
    });
  }, []);

  useEffect(() => {
    if (blogResponse) {
      setTotalPages(blogResponse.data.total_pages);
    }
  }, [blogResponse]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const isMobileDevice = window.navigator.userAgent
    .toLowerCase()
    .includes("mobi");
  return (
    <div className="main blog-page">
      {/* <Container disableGutters={useMediaQuery(theme.breakpoints.only('xs'))} className="blogs" > */}
      <div className="blogs">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <div className="blog-page-intro">
                <p className="Text bold-40 text-stroke-navy-color black">
                  Blogs
                </p>
                <br />
                <span className="blog-heading-text">
                  Explore the world of Ecommerce Same-Day Delivery &
                  Fulfillment. Stay ahead of the competition with our strategies
                  for Ecommerce!
                </span>
              </div>
            </Grid>

            <Grid item xs={12}>
              <CustomCarousel />
            </Grid>
          </Grid>
          <Grid className="blog-filters" container spacing={1}>
            <Grid item xs={6} lg={2}>
              <Autocomplete
                style={{
                  border: "0 !important",
                }}
                options={titleResponse ? titleResponse.data.blog : []}
                onChange={(newValue) => getBlog(newValue)}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    style={{
                      borderRadius: "25px",
                      border: "1px solid grey",
                      padding: "5px 10px 5px 15px",
                    }}
                    onChange={(e) => searchBlogs(e.target.value)}
                    {...params}
                    placeholder="Search"
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                      endAdornment: (
                        <>
                          <SearchIcon
                            color="action"
                            style={{ fontSize: "18px" }}
                          />
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6} lg={2}>
              <Autocomplete
                options={
                  categoryResponse ? categoryResponse?.data?.categories : []
                }
                // onChange={(event, newValue) => filterBlogs(newValue)}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    {...params}
                    style={{
                      borderRadius: "25px",
                      border: "1px solid grey",
                      padding: "5px 10px 5px",
                    }}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                    placeholder={`${
                      isMobileDevice ? "Category" : "Select Category"
                    }`}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            {blogResponse ? (
              blogResponse?.data?.results?.length === 0 ? (
                <Grid item xs={12}>
                  <div
                    className="no-data-image"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      src={require("../../assets/images/no-data.png")}
                      alt="No Data"
                    />
                  </div>
                </Grid>
              ) : (
                blogResponse?.data?.results?.map((item, index) => (
                  <Grid item xs={12} md={4} lg={3} key={index}>

                    <Card
                      className="blog-card"
                      sx={{ maxWidth: 500, maxHeight: 400 }}
                    >
                      <CardActionArea>
                        <Link to={`/blogs/${item.slug}`}>
                          <div className="card_component">
                            <CardMedia
                              component="img"
                              height="180"
                              image={
                                "https://zippee-delivery-assets.s3.amazonaws.com/" +
                                item.image
                              }
                              alt={item.title}
                            />
                          </div>
                        </Link>
                        <CardContent>
                          <Link to={`/blogs/${item.slug}`}>
                            <Typography
                              className="blog-title-name"
                              gutterBottom
                              variant="h5"
                              component="div"
                              style={{
                                background: "#F4C244",
                                color: "#705446",
                                width: "fit-content",
                                borderRadius: "5px",
                                padding: "2px 5px",
                              }}
                            >
                              {item.sub_category__category__title}
                            </Typography>
                            <Typography className="blog-sub">
                              {item?.title?.length > 48
                                ? item?.title?.slice(0, 48) + "..."
                                : item.title}
                            </Typography>
                          </Link>
                          <div className="blog-card-footer">
                            <Typography className="blog-date">
                              <CalendarMonthIcon />{" "}
                              <span style={{ marginTop: "6px" }}>
                                {item.date}
                              </span>
                            </Typography>
                            <div className="social-icons">
                              <Typography className="blog-socialmedia-icons">
                                <a
                                  href="https://instagram.com/zippeeindia?igshid=MzRlODBiNWFlZA=="
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <BsInstagram
                                    style={{ color: "red", fontSize: "19px" }}
                                  />
                                </a>
                                <a
                                  href="https://www.linkedin.com/company/zippee/"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <FaLinkedinIn
                                    style={{
                                      color: "#0a66c2",
                                      fontSize: "20px",
                                    }}
                                  />
                                </a>
                                <a
                                  href="https://twitter.com/zippeeindia"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <FaXTwitter style={{ fontSize: "20px" }} />
                                </a>
                                <a
                                  href="https://wa.me/+919650062127"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <RiWhatsappFill
                                    style={{ color: "green", fontSize: "20px" }}
                                  />
                                </a>
                              </Typography>
                            </div>
                          </div>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))
              )
            ) : null}
          </Grid>
          {/* <div>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              variant="text"
              shape="rounded"
            />
          </div> */}
          <div className="subscribe">
            <Grid style={{ fontSize: "25px" }}>
              <h2>
                Subscribe to our{" "}
                <TypeAnimation
                  sequence={[
                    "Newsletters ",
                    2000,
                    "Blogs ",
                    2000,
                    "Content ",
                    2000,
                    "Updates ",
                    2000,
                  ]}
                  wrapper="span"
                  cursor={true}
                  deletionSpeed={{ type: "keyStrokeDelayInMs", value: 20 }}
                  speed={{ type: "keyStrokeDelayInMs", value: 20 }}
                  repeat={Infinity}
                  style={{
                    display: "inline-block",
                    marginBottom: "10px",
                  }}
                  className="bold-40 secondary-color"
                />
              </h2>
            </Grid>
          </div>
          <Grid>
            <div className="blog_textmail-container">
              <div className="blog-business-mail">
                <input
                  className="blog_home-email-textf"
                  type="email"
                  placeholder="Enter your email"
                  onChange={(e) => setMail(e.target.value)}
                />
                <div>
                  <span className="mail-valid">{invalid ? msg : ""}</span>
                </div>
                <div className="postcard-request-access">
                  <div
                    className="postcard-request-button"
                    onClick={handleClickOpen}
                  >
                    Subscribe
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Box>
        {/* </Container> */}
      </div>
      <Dialog open={open} onClose={handleClose}>
        <div id="home-request-access-dialog-container">
          <DialogTitle id="home-request-access-dialog-title-container">
            <Text text="Almost There" appearance="bold-30" color="black" />
            <CancelIcon onClick={handleClose} style={listIconStyle} />
          </DialogTitle>
          <DialogContent>
            <FormComponent data={mail} />
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
};

export default Blogs;
